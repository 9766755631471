import React from "react";

import ProductCard from "../../../components/ProductCards/ProductCards";

import { BurdeosEco } from "../../../data/burdeos_eco";
import WineBottle from "../botellas_vino";

const BurdeosEcoView = () => {
  return (
    <WineBottle subtitle="Burdeos Eco">
      <ProductCard data={BurdeosEco} />
    </WineBottle>
  );
};

export default BurdeosEcoView;
